<template>
    <div class="container content-margin maxwidth_container">
        <div class="wrapper_live_detail">
            <div class="live_room">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 left_videohome">
                        <div class="videohome_lf bgr_video">
                            <vue3-video-player
                                autoplay
                                :core="HLSCore"
                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                title=""
                                resolution="408p"
                                :view-core="viewCore.bind(null, 'videolivecam')"
                                id="videolivecam"
                                v-if="idkc == 1"
                            />
                            <vue3-video-player
                                autoplay
                                :core="HLSCore"
                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                title=""
                                resolution="408p"
                                :view-core="viewCore.bind(null, 'videolivecam')"
                                id="videolivecam"
                                v-else-if="idkc == 2"
                            />
                            <vue3-video-player
                                autoplay
                                :core="HLSCore"
                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                title=""
                                resolution="408p"
                                :view-core="viewCore.bind(null, 'videolivecam')"
                                id="videolivecam"
                                v-else-if="idkc == 3"
                            />
                            <vue3-video-player
                                autoplay
                                :core="HLSCore"
                                :src="'https://cdn-2.nxplay.com.br/ESPN/tracks-v1a1/mono.m3u8'"
                                title=""
                                resolution="408p"
                                :view-core="viewCore.bind(null, 'videolivecam')"
                                id="videolivecam"
                                v-else
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="matchplay_live" v-if="listMatchApi.length > 0" style="display: none !important;">
                <div class="title_play">
                    <h3><img src="../assets/images/ddr.png" alt=""> Đang diễn ra</h3>
                </div>
                <div class="slider_play">
                    <Carousel :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(item, indexs) in listMatchApi.slice(0,10)" :key="indexs">
                            <div class="item_soccer">
                                <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                    <div class="tournament">
                                        <h2>{{ replaceName(item.result.match.competition.slug) }}</h2>
                                        <img src="../assets/images/liv.png" alt="">
                                    </div>
                                    <div class="content_soccer">
                                        <div class="home_soccer">
                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" alt="">
                                            <h3>{{ replaceName(item.result.match.homeTeam.slug) }}</h3>
                                        </div>
                                        <div class="btn_info_soccer">
                                            <img src="../assets/images/play.png" alt="">
                                            <p>{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</p>
                                            <p class="blv_ddr">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</p>
                                        </div>
                                        <div class="away_soccer">
                                            <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" alt="">
                                            <h3>{{ replaceName(item.result.match.awayTeam.slug) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </Slide>
                        <template #addons>
                            <pagination />
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="matchplay_live">
                <div class="title_play">
                    <h3><img src="../assets/images/ddr.png" alt=""> Truyền hình</h3>
                </div>
                <div class="slider_play">
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 item_kcong">
                            <router-link class="nav-item" v-bind:to="{ name: 'Kcong_vh', params: { id: 1 } }">
                                <div class="img_kcongvh">
                                    <img src="../assets/images/t1.png" alt="">
                                </div>
                            </router-link>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3 item_kcong">
                            <router-link class="nav-item" v-bind:to="{ name: 'Kcong_vh', params: { id: 2 } }">
                                <div class="img_kcongvh">
                                    <img src="../assets/images/t2.png" alt="">
                                </div>
                            </router-link>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3 item_kcong">
                            <router-link class="nav-item" v-bind:to="{ name: 'Kcong_vh', params: { id: 3 } }">
                                <div class="img_kcongvh">
                                    <img src="../assets/images/t3.png" alt="">
                                </div>
                            </router-link>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-3 item_kcong">
                            <router-link class="nav-item" v-bind:to="{ name: 'Kcong_vh', params: { id: 4 } }">
                                <div class="img_kcongvh">
                                    <img src="../assets/images/t4.png" alt="">
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="root_kcong_bner">
                <div class="wp_kcong">
                    <div class="kcong kcong1">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 1 } }">
                            <img src="../assets/images/kc1.png" alt="">
                        </router-link>
                    </div>
                    <div class="kcong kcong2">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 2 } }">
                            <img src="../assets/images/kc2.png" alt="">
                        </router-link>
                    </div>
                    <!-- <div class="kcong kcong3">
                        <router-link v-bind:to="{ name: 'Kcong_vh', params: { id: 3 } }">
                            <img src="../assets/images/htv.png" alt="">
                        </router-link>
                    </div> -->
                </div>
            </div>
            <div class="wrapper_match_home" v-if="listMatchApiAll.length > 0">
                <div class="title_match_home">
                    <h4><img src="../assets/images/sdr.png" alt=""> Sắp diễn ra</h4>
                </div>
                <div class="row row_match_home">
                    <div class="col-md-12 col-sm-12 col-xs-12 right_table_match">
                        <div class="tab-content">
                            <div class="allmatch">
                                <div>
                                    <div class="item-list" v-for="(item, index) in listMatchApiAll" v-bind:key="index">
                                        <router-link class="nav-item" v-bind:to="{ name: 'Live_vh', params: { id: item.result.match.id, check: item.result.match.matchStatus, url: item.result.match.homeTeam.slug + '-vs-' + item.result.match.awayTeam.slug + '-' +  item.result.match.id } }">
                                            <div class="row">
                                                <div class="col-md-5 col-sm-5 col-xs-12 left-list">
                                                    <div class="date-match">
                                                        <p class="competition_name nonedk" style="display:none;">{{ replaceName(item.result.match.competition.slug) }}</p>
                                                        <p>
                                                            <span class="date-lt">{{ convertDateStame(convertDatetime(item.result.match.matchTime)) }}</span>
                                                            <span class="nonemb">{{ replaceName(item.result.match.competition.slug) }}</span>
                                                            <span class="blv-class"><img src="../assets/images/live_1.gif" alt="" v-if="item.result.match.matchStatus == 2"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-7 col-sm-7 col-xs-12 center-list">
                                                    <div class="name-match">
                                                        <p class="">
                                                            <span class="name-lt">{{ replaceName(item.result.match.homeTeam.slug) }}</span>
                                                            <span class="logo-vs">
                                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.homeTeam.logo" class="img-logolt" alt="">
                                                                <span v-if="item.result.match.matchStatus == 2">{{ item.result.match.homeScores[0] }} - {{ item.result.match.awayScores[0] }}</span>
                                                                <span v-else>-</span>
                                                                <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.result.match.awayTeam.logo" class="img-logolt" alt="">
                                                            </span>
                                                            <span class="name-lt">{{ replaceName(item.result.match.awayTeam.slug) }}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_bxh_blv">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 left_bxh_home">
                        <DetailBxh_vh />
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-4 right_bxh_home" style="display: none !important;">
                        <div class="blv_home">
                            <div class="title_match_home">
                                <h4><img src="../assets/images/blv.png" alt=""> Top bình luận viên</h4>
                            </div>
                            <div class="root_blv_sc">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Tên bình luận viên</th>
                                            <th>Điểm</th>
                                            <th>Trạng thái</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in listblv" :key="index">
                                            <td>
                                                <span class="stt_blv">{{ index + 1 }}</span>
                                                <img :src="'https://vaohang.tech' + item.src_image" alt="">
                                                <span class="name_blv">{{ item.name }}</span>
                                            </td>
                                            <td>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.point) }}</td>
                                            <td><img src="../assets/images/live.png" alt=""></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="root_ratio_home ratio_tyle">
                <div class="wrapper_ratio_home">
                    <div class="title_ratio">
                        <h3>
                            <img src="../assets/images/tl.png" alt="">
                            <span>Tỷ lệ kèo</span>
                        </h3>
                        <ul>
                            <li>Tỷ lệ</li>
                            <li>Tài xỉu</li>
                            <li>1x2</li>
                        </ul>
                    </div>
                    <form action="" method="post" @change="GetDateRatio($event)">
                        <div class="wp_date_ratio_home">
                            <div class="date_ratio_home active">
                                <input type="radio" checked id="dateratio" class="dateratio" name="dateratio" :value="date1 + '_' + month1">
                                <label for="dateratio">Ngày {{ date1 + '/' + month1 }}</label>
                            </div>
                            <div class="date_ratio_home">
                                <input type="radio" id="dateratio1" class="dateratio" name="dateratio" :value="date2 + '_' + month2">
                                <label for="dateratio1">Ngày {{ date2 + '/' + month2 }}</label>
                            </div>
                            <div class="date_ratio_home">
                                <input type="radio" id="dateratio2" class="dateratio" name="dateratio" :value="date3 + '_' + month3">
                                <label for="dateratio2">Ngày {{ date3 + '/' + month3 }}</label>
                            </div>
                        </div>
                    </form>
                    <div class="ratio_home_wrapper">
                        <div class="wrapper_title_ratio_home">
                            <div class="title_ratio_home">
                                <div class="title_1">trận đấu</div>
                            </div>
                            <div class="title_ratio_home1">
                                <div class="title_2">
                                    <div class="div_1">
                                        Cả trận
                                    </div>
                                </div>
                            </div>
                            <div class="title_ratio_home1 title_ratio_home2">
                                <div class="title_2">
                                    <div class="div_1">
                                        Hiệp 1
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wrapper_item_ratio_home">
                            <div class="item_ratio_home item_ratio_home_live" v-for="(item, index) in data_name_ratio2" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio3.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item_ratio_home" v-for="(item, index) in data_name_ratio" :key="index">
                                <div class="wrapper_match_ratio">
                                    <div class="name_match_ratio">
                                        <div class="tournament_home">{{ item.leagueName }}</div>
                                        <p class="textred">{{ item.homeName }}</p>
                                        <p class="vsratio"><img src="../assets/images/vs.png" alt=""></p>
                                        <p>{{ item.awayName }}</p>
                                        <div class="date_ratio">{{ convertDate(item.showTimeDate) }}</div>
                                    </div>
                                    <div class="wrapper_ratio_all_match" v-for="(item, indexs) in data_name_ratio1.filter(d => d[0] == item.eventId)" :key="indexs">
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{item1.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item1, index1) in item[1][1]" :key="index1">
                                                        <p>{{ item1.odds1a }}</p>
                                                        <p>{{ item1.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{item3.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item3, index3) in item[1][3]" :key="index3">
                                                        <p>{{ item3.odds1a }}</p>
                                                        <p>{{ item3.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item5, index5) in item[1][5]" :key="index5">
                                                    <p>{{ item5.com1 }}</p>
                                                    <p>{{ item5.com2 }}</p>
                                                    <p>{{ item5.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ratio_all_home">
                                            <div class="ratio_all_match ratio_all_match0">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{item7.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item7, index7) in item[1][7]" :key="index7">
                                                        <p>{{ item7.odds1a }}</p>
                                                        <p>{{ item7.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_all_match">
                                                <div class="ratio_1">
                                                    <div class="fractionPoint_ratio" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{item8.fractionPoint}}</p>
                                                    </div>
                                                </div>
                                                <div class="ratio_2">
                                                    <div class="info_odds" v-for="(item8, index8) in item[1][8]" :key="index8">
                                                        <p>{{ item8.odds1a }}</p>
                                                        <p>{{ item8.odds2a }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ratio_half_match">
                                                <div class="ratio_3" v-for="(item15, index15) in item[1][15]" :key="index15">
                                                    <p>{{ item15.com1 }}</p>
                                                    <p>{{ item15.com2 }}</p>
                                                    <p>{{ item15.comx }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
import HLSCore from '@cloudgeek/playcore-hls'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

import $ from 'jquery'
$( document ).ready(function() {
    $(document).on("click",".tab_bxh ul li",function() {
        $('.tab_bxh ul li').removeClass('active');
        $(this).addClass('active');
    });
    $(document).on("click",".date_ratio_home label",function() {
        $('.date_ratio_home label').parent().removeClass('active');
        $(this).parent().addClass('active');
    });
});
export default {
    name: 'Kcong_vh',
    setup() {
        return {
            breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "center"
				},
				575: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "center"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "center"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
        }
    },
    data () {
        return {
            tabSelected: '',
            listItemdate1: [],
            tabs: '',
            country: 'Vietnam',
            players: {},
            volume: 90,
            HLSCore,
            hlight: [],
            listPlayMatch: [],
            check: '',
            checke: '',
            dataRatio: [],
            dataRatio1: [],
            dataRatio2: [],
            dataRatio3: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            data_name_ratio2: [],
            data_name_ratio3: [],
            date1: '',
            date2: '',
            date3: '',
            date4: '',
            month1: '',
            month2: '',
            month3: '',
            month4: '',
            datechange: '',
            listhot: [],
            idkc: parseInt(this.$route.params.id),
            linkKeo: '',
            linkCuoc: '',
            linkTele: '',
            linkZalo: '',
            linkLive: '',
            listblv: [],
            listMatchApi: [],
            listMatchApiAll: []
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        DetailBxh_vh
    },
    created () {
        this.getData()
        this.getlocation()
        this.getDataPlayMatch ()
        this.getDataRatio()
        this.getInfoLink()
        this.getListblv()
        this.getlistApiMatch()
    },
    mounted () {
        // const isMobile = /Mobi/i.test(navigator.userAgent)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    },
    beforeUnmount () {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.linkKeo = response.data.data.list_data.keo
                this.linkCuoc = response.data.data.list_data.cuoc
                this.linkTele = response.data.data.list_data.telegram
                this.linkZalo = response.data.data.list_data.zalo
                this.linkLive = response.data.data.list_data.live
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
            this.tabs = tab
        },
        getData () {
            this.axios.get(this.api_listmatchdate).then((response) => {
                this.listItemdate1 = response.data.data
                this.tabSelected = Object.keys(this.listItemdate1)[0];
                this.tabs = Object.keys(this.listItemdate1)[0];
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        playEnded() {
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        getVideohl () {
            this.axios.get(this.api_highlight).then((response) => {
                var hl = response.data.items
                const getRandom = Math.floor(Math.random() * hl.length);
                this.hlight = hl.find( el => getRandom == el.mid);
            })
        },
        getDataPlayMatch () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.listhot = response.data.data.filter(d => d.hot === 1)
                this.listPlayMatch = response.data.data.filter(d => d.live === 1)
            })
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                this.checkes = e
                this.check = 0
            })
            return this.check;
        },
        getDataRatio () {
            let today = new Date();
            let date = today.getDate();
            if(date < 10){
                date = '0' + date
            }
            let month = today.getMonth()+1;
            let date_month = date + '_' + month;
            const form = new FormData();
            form.append('day', date_month);

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                this.dataRatio2 = response.data.oddData.live.event_map; // lấy trận
                this.dataRatio3 = response.data.oddData.live.odd_map;
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));
                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                const arr_match1 = Object.entries(this.dataRatio2).map((arr) => ({
                    ...arr[1]
                }));
                const arr_ratio1 = Object.entries(this.dataRatio3).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
                this.data_name_ratio2 = arr_match1
                this.data_name_ratio3 = arr_ratio1
            })
        },
        GetDateRatio (event) {
            this.datechange = event.target.value
            if (this.datechange != this.date1 + '_' + this.month1) {
                $('.item_ratio_home_live').hide()
            }else{
                $('.item_ratio_home_live').show()
            }

            const form = new FormData();
            form.append('day', this.datechange);
            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.today.event_map; // lấy trận
                this.dataRatio1 = response.data.oddData.today.odd_map; //lấy tỷ lệ
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));
                const arr_ratio = Object.entries(this.dataRatio1).map((arrs) => ({
                    ...arrs
                }));
                this.data_name_ratio = arr_match
                this.data_name_ratio1 = arr_ratio
            })
        },
        clickToTop () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        convertDate (date) {
            var datecv = new Date(date);
            return datecv.toLocaleString()
        },
        onEditorReady(editor) {
            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    this.sendChats()
                    editor.setData( '<p></p>' );
                    evt.stop();
                }
                    data.preventDefault();
                    evt.stop();
                    editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        },
        getListblv () {
            this.axios.get(this.api_blv).then((response) => {
                this.listblv = response.data.data
            })
        },
        getlistApiMatch () {
            this.axios.get(this.api_matchCola).then((response) => {
                this.listMatchApi = response.data.data.filter(d => d.result.match.matchStatus == 2)
                this.listMatchApiAll = response.data.data
            })
        },
        convertDatetime(d) {
            let gt7 = 7 * 3600
            let timeStamp_change = Number(d) + gt7
            return new Date(timeStamp_change * 1000).toISOString().slice(0, 19).replace('T', ' ')
        },
        replaceName (str) {
            return str.replaceAll('-', ' ')
        },
        convertDateStame (d) {
            let arr = d.split(' ')
            let ngaythang = arr[0]
            let arrNt = ngaythang.split('-')
            let giophut = arr[1]
            let arrGp = giophut.split(':')
            return arrNt[2] + '/' + arrNt[1] + ' ' + arrGp[0] + ':' + arrGp[1]
        },
    }
}
</script>

<style scoped>
</style>
