<template>
    <Header_Vaohang :class="{ header_iframe: this.$route.name == 'Livevh_vh'}" />
        <router-view :key ='$route.params'/>
        <!-- <Chat_Vaohang v-if="this.$route.name != 'Livevh_vh'" :class="{noneChat: $route.name == 'Live_vh'}"/> -->
    <Footer_Vaohang :class="{noneFooter: $route.name == 'Live_vh'}"/>
</template>

<script>
import "./assets/css/style.css"
import Header_Vaohang from './components/Header.vue'
import Footer_Vaohang from './components/Footer.vue'
// import Chat_Vaohang from './components/Chat.vue'

export default {
    name: 'App',
    components: {
        Header_Vaohang,
        Footer_Vaohang,
        // Chat_Vaohang
    }
}
</script>

<style>
</style>
