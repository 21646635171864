<template>
    <div class="root_bxhedit_home">
        <div class="bxh_home">
            <div class="title_match_home">
                <h4><img src="../assets/images/bxh.png" alt=""> Bảng xếp hạng</h4>
            </div>
        </div>
        <div class="root_bxh_home">
            <div class="tab_bxh">
                <ul>
                    <li v-bind:class="{active: tabSelected == index}" v-on:click="changeTab(index)" v-for="(item, index) in listGroup.slice(0,7)" :key="index" :data_id="item.id">{{ item.name }}</li>
                </ul>
            </div>
            <div class="content_bxh_home">
                <div class="item_bxh_list">
                    <div v-if="tabSelected === 0">
                        <div class="table_bxh_home">
                            <table v-if="idFirst == 2 || idFirst == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league table_league_bgr">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 1">
                        <div class="table_bxh_home">
                            <table v-if="idFirst1 == 2 || idFirst1 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart1" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart1" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 2">
                        <div class="table_bxh_home">
                            <table v-if="idFirst2 == 2 || idFirst2 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart2" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league table_league_bgr">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart2" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 3">
                        <div class="table_bxh_home">
                            <table v-if="idFirst3 == 2 || idFirst3 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart3" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league table_league_bgr">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart3" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 4">
                        <div class="table_bxh_home">
                            <table v-if="idFirst4 == 2 || idFirst4 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart4" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league table_league_bgr">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart4" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 5">
                        <div class="table_bxh_home">
                            <table v-if="idFirst5 == 2 || idFirst5 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart5" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart5" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="tabSelected === 6">
                        <div class="table_bxh_home">
                            <table v-if="idFirst6 == 2 || idFirst6 == 3" class="table_group_league">
                                <tbody>
                                    <tr v-for="(item, index) in listChart6" :key="index">
                                        <td colspan="8" class="td_css">
                                            <table border="1" width="100%">
                                                <tr>
                                                    <th width="37%" class="wd_mobile" colspan="2">Đội</th>
                                                    <th>Tr</th>
                                                    <th>T</th>
                                                    <th>H</th>
                                                    <th>Th</th>
                                                    <th>Hs</th>
                                                    <th>Đ</th>
                                                </tr>
                                                <tr colspan="8" class="border_class_hm">{{item[0].group }}</tr>
                                                <tr v-for="(items, indexs) in item" :key="indexs" :class="'cssspan csstr_'+indexs">
                                                    <td width="7%"><span>{{ indexs+1 }}</span></td>
                                                    <td width="37%" class="td_left"><img :src="items.team.logo" alt="">{{ items.team.name }}</td>
                                                    <td>{{ items.all.played }}</td>
                                                    <td>{{ items.all.win }}</td>
                                                    <td>{{ items.all.draw }}</td>
                                                    <td>{{ items.all.lose }}</td>
                                                    <td>{{ items.all.goals.for - items.all.goals.against }}</td>
                                                    <td>{{ items.points }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else class="table_league table_league_bgr">
                                <thead>
                                    <tr>
                                        <th width="37%" colspan="2">Đội</th>
                                        <th>Tr</th>
                                        <th>T</th>
                                        <th>H</th>
                                        <th>Th</th>
                                        <th>Hs</th>
                                        <th>Đ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listChart6" :key="index">
                                        <td width="7%"><span :class="'stt_'+index">{{ index+1 }}</span></td>
                                        <td width="37%" class="td_left"><img :src="item.team.logo" alt="">{{ item.team.name }}</td>
                                        <td>{{ item.all.played }}</td>
                                        <td>{{ item.all.win }}</td>
                                        <td>{{ item.all.draw }}</td>
                                        <td>{{ item.all.lose }}</td>
                                        <td>{{ item.all.goals.for - item.all.goals.against }}</td>
                                        <td>{{ item.points }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Bxh_right from '@/components/Bxhright'
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on("click",".tab_bxh ul li",function() {
        $('.tab_bxh ul li').removeClass('active');
        $(this).addClass('active');
    });
});
export default {
    name: 'DetailBxh_vh',
    data () {
        return {
            listGroup: [],
            idFirst: 39,
            idFirst1: '',
            idFirst2: '',
            idFirst3: '',
            idFirst4: '',
            idFirst5: '',
            idFirst6: '',
            listChart: [],
            listChart1: [],
            listChart2: [],
            listChart3: [],
            listChart4: [],
            listChart5: [],
            listChart6: [],
            localName: window.localStorage.getItem('nameCountry'),
            country_leagua: '39',
            tabSelected: 0,
        }
    },
    components: {
    },
    created () {
        window.localStorage.setItem('nameCountry', '39')
        this.GetDateCountry()
        setTimeout(() => this.GetDateChart(), 1000);
        setTimeout(() => this.GetDateChart1(), 1000);
        setTimeout(() => this.GetDateChart2(), 1000);
        setTimeout(() => this.GetDateChart3(), 1000);
        setTimeout(() => this.GetDateChart4(), 1000);
        setTimeout(() => this.GetDateChart5(), 1000);
        setTimeout(() => this.GetDateChart6(), 1000);
    },
    methods: {
        // changeTab1 (tab) {
        //     this.tabSelected1 = tab
        // },
        changeTab (tab) {
            this.tabSelected = tab
        },
        GetDateCountry () {
            this.axios.get(this.api_countrybxh).then((response) => {
                const listarr = Object.entries(response.data.items[0]).map((arr) => ({
                    name: arr[0],
                    id: arr[1],
                }));
                this.listGroup = listarr
                let idfirstchild = listarr.slice(0,7)
                this.idFirst = Number(idfirstchild[0].id)
                this.idFirst1 = Number(idfirstchild[1].id)
                this.idFirst2 = Number(idfirstchild[2].id)
                this.idFirst3 = Number(idfirstchild[3].id)
                this.idFirst4 = Number(idfirstchild[4].id)
                this.idFirst5 = Number(idfirstchild[5].id)
                this.idFirst6 = Number(idfirstchild[6].id)
            })
        },
        GetDateChart () {
            this.axios.get(this.api_bxh + '?league_id=' + this.idFirst).then(res => {
                if(this.idFirst==2 || this.idFirst == 3){
                    this.listChart = res.data.data.standings
                }else{
                    this.listChart = res.data.data.standings[0]
                }
            })
        },
        GetDateChart1 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst1).then(res => {
                if(this.idFirst1==2 || this.idFirst1 == 3){
                    this.listChart1 = res.data.data.standings
                }else{
                    this.listChart1 = res.data.data.standings[0]
                }
            })
        },
        GetDateChart2 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst2).then(res => {
                if(this.idFirst2==2 || this.idFirst2 == 3){
                    this.listChart2 = res.data.data.standings
                }else{
                    this.listChart2 = res.data.data.standings[0]
                }
            })
        },
        GetDateChart3 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst3).then(res => {
                if(this.idFirst3==2 || this.idFirst3 == 3){
                    this.listChart3 = res.data.data.standings
                }else{
                    this.listChart3 = res.data.data.standings[0]
                }
            })
        },
        GetDateChart4 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst4).then(res => {
                if(this.idFirst4==2 || this.idFirst4 == 3){
                    this.listChart4 = res.data.data.standings
                }else{
                    this.listChart4 = res.data.data.standings[0]
                }
            })
        },
        GetDateChart5 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst5).then(res => {
                if(this.idFirst5==2 || this.idFirst5 == 3){
                    this.listChart5 = res.data.data.standings
                }else{
                    this.listChart5 = res.data.data.standings[0]
                }
            })
        },
        GetDateChart6 () {
            this.axios.get(this.api_bxh + '?league_id='  + this.idFirst6).then(res => {
                if(this.idFirst6==2 || this.idFirst6 == 3){
                    this.listChart6 = res.data.data.standings
                }else{
                    this.listChart6 = res.data.data.standings[0]
                }
            })
        },
        // ChangeData (event) {
        //     event.preventDefault();
        //     var dataid = event.target.value
        //     window.localStorage.setItem('nameCountry', dataid)
        //     this.idFirst = dataid
        //     const form = new FormData();
        //     form.append('league_id', dataid);
        //     this.axios.post(this.api_listbxh, 
        //         form,
        //     ).then(res => {
        //         if(dataid == 2 || dataid == 3){
        //             this.listChart = res.data.response[0]?.league.standings
        //         }else{
        //             this.listChart = res.data.response[0]?.league.standings[0]
        //         }
        //     })
        // },
        // ChangeData (event) {
        //     event.preventDefault();
        //     $(this).addClass('111')
        //     var dataid = Number(event.target.getAttribute('data_id'))
        //     // var dataid = event.target.value
        //     window.localStorage.setItem('nameCountry', dataid)
        //     this.idFirst = dataid
        //     // const form = new FormData();
        //     // form.append('league_id', dataid);
        //     this.axios.get(this.api_bxh + '?league_id=' + this.idFirst).then(res => {
        //         if(dataid == 2 || dataid == 3){
        //             this.listChart = res.data.data.standings
        //         }else{
        //             this.listChart = res.data.data.standings[0]
        //         }
        //     })
        // },
    }
}
</script>
<style>
</style>
