<template>
    <div class="container page_bxh-vh content-margin">
        <div class="wrapper_bxh_blv">
            <DetailBxh_vh />
        </div>
    </div>
</template>

<script>
import DetailBxh_vh from '@/components/DetailBxh'
export default {
    name: 'Bxh_vh',
    data () {
        return {
        }
    },
    components: {
        DetailBxh_vh
    },
    created () {
    },
    methods: {
    }
}
</script>
<style>
</style>
