<template>
    <!-- <div class="container content-margin">
        <div class="row row-main main_page_edit">
            <div class="large-12 col ltd_container">
                <div class="col-inner root_result">
                    <div class="wp-schedule wrapper_match_calenda wp_result_soccer">

                        <div class="item_tournament item_tournament_result" v-for="(item, index) in listKqbd" :key="index">
                            <h3 :class="'title_none title' + indexs"><span>{{ item.profile.name }}</span></h3>
                            <div class="wp_item_tournament" v-for="(items, indexs) in item.groupMatchs[0].listMatches" :key="indexs">
                                <div class="match_tournament">
                                    <div class="div_02">{{ formatTimeSramp(items.matchTime) }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ items.homeName }} <img :src="items.homeIcon" alt=""></span>
                                        <span class="vs_ltd">{{ items.homeScore }} - {{ items.awayScore }}</span>
                                        <span class="name_away_ltd"><img :src="items.awayIcon" alt=""> {{ items.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                                        

                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div id="content" class="content-area page-wrapper container content-margin" role="main">
        <div class="row row-main main_page_edit">
            <div class="large-12 col ltd_container">
                <div class="col-inner">
                    <div class="wp-schedule wrapper_match_calenda">

                        <div class="item_tournament" v-for="(items, indexs) in listKqbd" :key="indexs">
                            <div class="wp_item_tournament">
                                <h3><span>{{ items.profile.name }}</span></h3>
                                <div class="match_tournament" v-for="(item1, index1) in items.groupMatchs[0].listMatches" :key="index1">
                                    <div class="div_02">{{ formatTimeSramp(item1.matchTime) }}</div>
                                    <div class="div_04">
                                        <!-- <span class="name_home_ltd">{{ item1.homeName }} <img :src="item1.homeIcon" alt=""></span>
                                        <span class="vs_ltd">{{ item1.homeScore }} - {{ item1.awayScore }} </span>
                                        <span class="name_away_ltd"><img :src="item1.awayIcon" alt=""> {{ item1.awayName }}</span> -->
                                        <span class="name_home_ltd">{{ item1.homeName }}</span>
                                        <span class="vs_ltd">{{ item1.homeScore }} - {{ item1.awayScore }} </span>
                                        <span class="name_away_ltd">{{ item1.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'Kqbd_vh',
    data () {
        return {
            Listitems: [],
            dataRs: [],
            dataRs1: [],
            listKqbd: [],
            httpImg: ''
        }
    },
    components: {
    },
    created () {
        this.getDataKetqua()
    },
    methods: {
        getDataKetqua () {
            let today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate()
            this.axios.get(this.api_ltds + '?date=' + date).then((response) => {
                this.listKqbd = response.data.result
            });
        },
        getTournament () {
            this.axios.get(this.api_kqbd).then((response) => {
                this.dataRs = response.data.data.competitionMap;
                this.dataRs1 = response.data.data.eventMap;

                const arr_Result = Object.entries(this.dataRs).map((arr) => ({
                    ...arr[1]
                }));
                const sortData = arr_Result.sort((a, b) => {
                    return (b.isPin || 0) - (a.isPin || 0);
                });
                const arr_Result1 = Object.entries(this.dataRs1).map((arr1) => ({
                    ...arr1[1]
                }));

                var listRs = [];
                sortData.forEach((item)=>{
                    let idCompetition = item.competitionId
                    var val_1 = arr_Result1.filter( product => {
                        return product.competitionId == idCompetition
                    })
                    listRs.push(val_1)
                });
                this.Listitems = listRs
            })
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('HH:mm DD/MM')
            }
        },
        formatTimeSramp (str) {
            const timestamp = str * 1000;
            const date = new Date(timestamp);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            // const yyyy = date.getFullYear();
            const hh = String(date.getHours()).padStart(2, '0');
            const ii = String(date.getMinutes()).padStart(2, '0');
            // const ss = String(date.getSeconds()).padStart(2, '0');
            const formattedDate = `${dd}/${mm} ${hh}:${ii}`;
            return formattedDate;
        },
        // splitImg (img) {
        //     let linkImg = img.split(':');
        //     if(linkImg[0] == 'http'){
        //         this.httpImg = 'https:';
        //     }else{
        //         this.httpImg = linkImg[0] + ':';
        //     }
        //     return this.httpImg + linkImg[1];
        // }
    }
}
</script>
<style>
</style>
