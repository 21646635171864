<template>
    <div id="content" class="content-area page-wrapper container content-margin" role="main">
        <div class="row row-main">
            <div class="large-12 col">
                <div class="col-inner" v-for="(item, index) in items" v-bind:key="index">
                    <div class="content-section mb-4" id="sec_live_match">
                        <div class="row">
                            <div class="col-lg-12" id="live-header">
                                <div class="top_section">
                                    <div class="block_info">
                                        <h5>{{ item.name }}</h5>
                                        <!-- <div class="name_club">{{ item.name }}</div> -->
                                    </div>
                                    <div class="block_share" style="display:none !important;">
                                        <div class="share">
                                            <span class="txt">Chia sẻ ngay</span>
                                            <div class="addthis_inline_share_toolbox" v-bind:data-url="url" data-title="Xem trực tiếp bình luận bóng đá hôm nay: Highlights" data-description="Link xem bóng đá trực tuyến tốt nhất, siêu mượt fullhd bình luận tiếng Việt. Tường thuật trực tiếp tại vaohang.tv " style="clear: both;">
                                                <div id="atstbx" class="at-resp-share-element at-style-responsive addthis-smartlayers addthis-animated at4-show" aria-labelledby="at-b3b5a3dc-7439-413e-bf7b-cefc3d7adb77" role="region">
                                                    <span id="at-b3b5a3dc-7439-413e-bf7b-cefc3d7adb77" class="at4-visually-hidden">AddThis Sharing Buttons</span>
                                                    <div class="at-share-btn-elements">
                                                        <a v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-facebook" style="background-color: rgb(59, 89, 152); border-radius: 4px;">
                                                            <span class="at4-visually-hidden">Share to Facebook</span>
                                                            <span class="at-icon-wrapper" style="line-height: 20px; height: 20px; width: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-facebook-1" class="at-icon at-icon-facebook" style="fill: rgb(255, 255, 255); width: 20px; height: 20px;">
                                                                    <title id="at-svg-facebook-1">Facebook</title>
                                                                    <g>
                                                                        <path d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z" fill-rule="evenodd"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <a v-bind:href="'https://telegram.me/share/url?url=' + currentUrl" target="_blank" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-telegram" style="background-color: rgb(0, 136, 204); border-radius: 4px;">
                                                            <span class="at4-visually-hidden">Share to Telegram</span>
                                                            <span class="at-icon-wrapper" style="line-height: 20px; height: 20px; width: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-telegram-3" class="at-icon at-icon-telegram" style="fill: rgb(255, 255, 255); width: 20px; height: 20px;">
                                                                    <title id="at-svg-telegram-3">Telegram</title>
                                                                    <g>
                                                                        <g fill-rule="evenodd"></g>
                                                                        <path d="M15.02 20.814l9.31-12.48L9.554 17.24l1.92 6.42c.225.63.114.88.767.88l.344-5.22 2.436 1.494z" opacity=".6"></path>
                                                                        <path d="M12.24 24.54c.504 0 .727-.234 1.008-.51l2.687-2.655-3.35-2.054-.344 5.22z" opacity=".3"></path>
                                                                        <path d="M12.583 19.322l8.12 6.095c.926.52 1.595.25 1.826-.874l3.304-15.825c.338-1.378-.517-2.003-1.403-1.594L5.024 14.727c-1.325.54-1.317 1.29-.24 1.625l4.98 1.58 11.53-7.39c.543-.336 1.043-.156.633.214"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <a @click="copyToClipBoard(currentUrl)" id="copyClick" role="button" tabindex="0" class="at-icon-wrapper at-share-btn at-svc-link" style="background-color: rgb(23, 139, 244); border-radius: 4px;">
                                                            <span class="at4-visually-hidden">Share to Copy Link</span>
                                                            <span class="at-icon-wrapper" style="line-height: 20px; height: 20px; width: 20px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-link-4" class="at-icon at-icon-link" style="fill: rgb(255, 255, 255); width: 20px; height: 20px;">
                                                                    <title id="at-svg-link-4">Copy Link</title>
                                                                    <g>
                                                                        <path d="M23.476 20.663c0-.324-.114-.6-.34-.825l-2.524-2.524a1.124 1.124 0 0 0-.826-.34c-.34 0-.63.13-.873.388.024.024.1.1.23.225s.217.212.26.26c.046.05.106.126.183.23a.976.976 0 0 1 .2.644c0 .325-.113.6-.34.827-.226.226-.5.34-.825.34-.12 0-.23-.015-.332-.043a.976.976 0 0 1-.31-.158 2.89 2.89 0 0 1-.23-.182 7.506 7.506 0 0 1-.26-.26l-.226-.23c-.267.25-.4.545-.4.885 0 .322.113.597.34.824l2.5 2.512c.218.218.493.328.825.328.323 0 .598-.106.825-.316l1.784-1.772a1.11 1.11 0 0 0 .34-.813zm-8.532-8.556c0-.323-.113-.598-.34-.825l-2.5-2.512a1.124 1.124 0 0 0-.825-.34c-.316 0-.59.11-.826.328L8.67 10.53a1.11 1.11 0 0 0-.34.813c0 .323.113.598.34.825l2.524 2.524c.22.22.494.328.825.328.34 0 .63-.126.873-.376-.024-.025-.1-.1-.23-.225a7.506 7.506 0 0 1-.26-.262 2.89 2.89 0 0 1-.183-.23.976.976 0 0 1-.2-.644c0-.323.113-.598.34-.825.226-.227.5-.34.824-.34a.976.976 0 0 1 .643.2c.106.077.183.137.23.182.05.044.137.13.262.26s.2.207.224.23c.267-.25.4-.545.4-.885zm10.862 8.556c0 .97-.344 1.792-1.032 2.464L22.99 24.9c-.67.67-1.492 1.006-2.463 1.006-.98 0-1.805-.344-2.476-1.032l-2.5-2.512c-.67-.67-1.006-1.493-1.006-2.463 0-.997.356-1.842 1.068-2.538l-1.068-1.068c-.696.712-1.538 1.068-2.525 1.068-.97 0-1.797-.34-2.476-1.02L7.02 13.82C6.34 13.138 6 12.314 6 11.343c0-.97.344-1.792 1.032-2.464l1.784-1.773c.67-.67 1.492-1.007 2.463-1.007.978 0 1.803.344 2.475 1.032l2.5 2.512c.67.67 1.007 1.492 1.007 2.463 0 .995-.356 1.84-1.068 2.537l1.068 1.068c.696-.712 1.537-1.068 2.524-1.068.97 0 1.797.34 2.476 1.02l2.524 2.523c.68.68 1.02 1.505 1.02 2.476z" fill-rule="evenodd"></path>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-section">
                        <div class="row">
                            <div class="col-lg-9 xs-12" id="live-body_ss">
                                <video width="100%" height="500" autoplay controls class="video-highlight-detail" style="width: 100%;">
                                    <source v-bind:src="'https://vaohang.info/video-uploads/' + item.videopath" type="video/mp4">
                                </video>
                            </div>
                            <div class="col-lg-3 xs-12" style="padding: 0;">
                                <div class="container-fluid mb-4" id="live-sidebar_ss" v-for="(item_rg, index) in item_right" v-bind:key="index">
                                    <router-link class="nav-item" v-bind:to="{ name: 'Highlightvideo_vh', params: { id: item_rg.mid } }">
                                        <div class="container" style="margin-top: 10px;">
                                            <div class="row">
                                                <div class="col-4 img_hl_dt">
                                                    <img class="lazyload" v-bind:src="'https://vaohang.info/uploads/' + item_rg.imgpath" alt="">
                                                </div>
                                                <div class="col-8 name_hg_detail">{{ item_rg.name }}</div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Highlightvideo_vh",
    data () {
        return {
            id: this.$route.params.id,
            items: [],
            url: '',
            item_right: [],
            currentUrl: location.toString(),
            limit: 8
        }
    },
    created () {
        // this.getHighlightDetail(this.id)
        this.getListHighlightrg()
    },
    methods: {
        getHighlightDetail (id) {
            this.axios.get(this.api_highlight).then((response) => {
                this.items = response.data.items.filter(d => d.mid === id);
            })
        },
        copyToClipBoard (textToCopy) {
            navigator.clipboard.writeText(textToCopy)
        },
        getListHighlightrg () {
            this.axios.get(this.api_highlight).then((response) => {
                // this.item_right = response.data.items.filter((el, index) => index < this.limit)
                this.item_right = response.data.items.filter(d => d.mid !== this.id).slice(0, 8)
            })
        }
    }
}
</script>
<style>
</style>
