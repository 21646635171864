<template>
    <div id="content" class="content-area page-wrapper container content-margin" role="main">
        <!-- <div class="icon_adv_mobile icon_adv_mobile1">
            <ul>
                <li>
                    <a :href="link_keobong" target="_blank"><img src="../assets/images/btn1.gif" alt=""></a>
                </li>
                <li>
                    <a :href="link_banner" target="_blank"><img src="../assets/images/btn.gif" alt=""></a>
                </li>
            </ul>
        </div> -->
        <div class="row-main main_page_edit">
            <div class="large-12 col">
                <div class="col-inner">
                    <div class="mb-4 home-section section-news section-video">
                        <div class="container">
                            <div class="row">
                                <!-- <div class="sec_title mb-4">
                                    <span class="txt">VIDEO highlight<img class="lazy-load-active" src="../assets/images/arrow_right.svg" alt=""></span>
                                </div> -->
                                <div class="content-section">
                                    <div id="list-highlight_ss" class="row">
                                        <video width="100%" height="640" autoplay loop controls class="video-highlight-home" style="width: 100%;">
                                            <source src="https://f8game.click/tvc/tvc_vaohang.mp4" type="video/mp4">
                                        </video>
                                        <!-- <article class="col-md-3 mb-3 post" v-for="(item, index) in datas" v-bind:key="index">
                                            <div class="blog-grid-wrapper">
                                                <div class="row align-items-center">
                                                    <div class="col-5 col-md-12">
                                                        <div class="blog-grid-img">
                                                            <router-link class="nav-item" v-bind:to="{ name: 'Highlightvideo_vh', params: { id: item.mid } }">
                                                                <img class="lazyload" v-bind:src="'https://vaohang.info/uploads/' + item.imgpath" alt="">
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                    <div class="col-7 col-md-12">
                                                        <div class="blog-grid-content">
                                                            <router-link class="ellipsis3 blog-grid-post-title" v-bind:to="{ name: 'Highlightvideo_vh', params: { id: item.mid } }">{{ item.name }}</router-link>
                                                            <p class="d-none d-lg-block mt-2 date-blog">
                                                                {{ format_date(item.create_date) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article> -->
                                    </div>
                                   <!-- <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import moment from 'moment'
export default {
    name: 'Highlight_vh',
    data () {
        return {
            page: 3,
            perPage: 12,
            totalCount: 0,
            items: [],
            datas: []
        }
    },
    created () {
        // this.getListHighlight()
    },
    methods: {
        // getListHighlight () {
        //     this.axios.get(this.api_highlight).then((response) => {
        //         this.items = response.data.items
        //         this.totalCount = this.items.length
        //         this.paginatedData()
        //     })
        // },
        // format_date(value){
        //     if (value) {
        //         return moment(String(value)).format('DD/MM/YYYY')
        //     }
        // },
        // paginatedData () {
        //     this.datas = this.items.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        // },
        // clickCallback (pageNum) {
        //     this.page = pageNum;
        //     this.paginatedData();
        // }
    }
}
</script>
<style>

</style>
