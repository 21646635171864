<template>
    <div class="full_screnn" v-for="(lives, indexs) in liveRoom" v-bind:key="indexs">
        <div class="wrapper_live_detail" :class="{active: lives.live==1 && retrieveAllCustomer(lives.link_live)==1}">
            <div class="video_vietnam_iframe" v-if="country=='Vietnam'">
                <div class="video_edit_iframe">
                    <vue3-video-player
                        autoplay
                        :core="HLSCore"
                        :src="lives.link_live2"
                        title=""
                        resolution="408p"
                        :view-core="viewCore.bind(null, 'videolivecamIf')"
                        id="videolivecamIf"
                    />
                </div>
            </div>
            <div class="video_edit_iframe" v-else>
                <vue3-video-player
                    autoplay
                    :core="HLSCore"
                    :src="lives.link_live2"
                    title=""
                    resolution="408p"
                    :view-core="viewCore.bind(null, 'videolivecamIf')"
                    id="videolivecamIf"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HLSCore from '@cloudgeek/playcore-hls'

import $ from 'jquery'
$( document ).ready(function() {
    // $(document).on('click', '.hide_chat', function() {
    //     // 
    // });
});
export default {
    name: 'Livevh_vh',
    data () {
        return {
            liveRoom: [],
            country: 'Vietnam',
            players: {},
            volume: 90,
            HLSCore,
            idLv: parseInt(this.$route.params.id),
            urlLv: this.$route.params.url,
            checkMobile: 1,
            check: 0,
            other: ''
        }
    },
    components: {
    },
    created () {
        this.getDataRoomLive()
        this.getlocation()
        this.hiddenScroll()
    },
    mounted () {
        const isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile){
            this.checkMobile = 2
        }else{
            this.checkMobile = 1
        }
    },
    beforeUnmount () {
        
    },
    methods: {
        getDataRoomLive () {
            this.axios.get(this.api_listmatchlive).then((response) => {
                this.liveRoom = response.data.data.filter(d => d.id === this.idLv)
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                this.other = e
                this.check = 0
            })
            return this.check;
        },
        hiddenScroll () {
            $('html').addClass('hiddenScroll')
        }
    }
}
</script>

<style scoped>
</style>
