<template>
    <div class="wrapper_new">
        <section class="home-section section-news section-news-2">
            <div class="container new-home-ed">
                <div class="icon_adv_mobile icon_adv_mobile1">
                    <ul>
                        <li>
                            <a :href="link_keobong" target="_blank"><img src="../assets/images/btn1.gif" alt=""></a>
                        </li>
                        <li>
                            <a :href="link_banner" target="_blank"><img src="../assets/images/btn.gif" alt=""></a>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 padding_editor">
                        <div class="d-flex justify-content-between align-items-center text-uppercase section-title">
                            <span class="d-flex align-items-center title-section">
                                <div class="sec_title tit-new" style="margin-left: -5px;">
                                    <span class="txt">TIN TỨC BÓNG ĐÁ HÔM NAY <img src="../assets/images/arrow_right.svg" alt=""></span>
                                </div>
                            </span>
                        </div>
                        <div class="row row-bxh-home">
                            <div class="col-md-12 col-sm-12 col-xs-12 left-new-home">
                                <div class="content-section">
                                    <div class="row no-gutters" id="new_api_vaohang">
                                        <article class="col-md-3 mb-3 item_post_api post" v-for="(itemnew, index) in listNew"  v-bind:key="index">
                                            <div class="blog-grid-wrapper">
                                                <div class="row align-items-center">
                                                    <div class="col-5 col-md-12">
                                                        <div class="blog-grid-img maxheight_blog">
                                                            <a rel="nofollow" target="_blank" v-bind:href="'https://vaohang.com/'+ itemnew.post_name" class="thumbnail">
                                                                <img class="lazyload" alt="" v-bind:src="'https://vaohang.com/wp-content/uploads/' + itemnew.meta_value">
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-7 col-md-12">
                                                        <div class="blog-grid-content">
                                                            <a rel="nofollow" target="_blank" v-bind:href="'https://vaohang.com/'+ itemnew.post_name" class="ellipsis3 blog-grid-post-title" v-html="itemnew.post_title"></a>
                                                            <p class="d-none d-lg-block mt-2 date-blog" style="color:#777;">{{ format_date_new(itemnew.post_date_gmt) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-4 col-xs-12 right-bxh-home" style="display: none;">
                                <div class="select_country">
                                    <ul class="chosse_country">
                                        <li>
                                            <span class="text_country">Anh</span>
                                            <ul class="click_add_val">
                                                <li v-on:click="changeTab1('tab_country_1')" data_value="Anh">Anh</li>
                                                <li v-on:click="changeTab1('tab_country_2')" data_value="Tây Ban Nha">Tây Ban Nha</li>
                                                <li v-on:click="changeTab1('tab_country_3')" data_value="Đức">Đức</li>
                                                <li v-on:click="changeTab1('tab_country_4')" data_value="Ý">Ý</li>
                                                <li v-on:click="changeTab1('tab_country_5')" data_value="Pháp">Pháp</li>
                                                <li v-on:click="changeTab1('tab_country_6')" data_value="Bồ Đào Nha">Bồ Đào Nha</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tab_country_sc">
                                    <div class="tab_country_1" v-if="tabSelected1 === 'tab_country_1'">
                                        <iframe src="https://vaohang.com/?p=219" frameborder="0" width="100%"></iframe>
                                    </div>
                                    <div class="tab_country_2" v-if="tabSelected1 === 'tab_country_2'">
                                        <iframe src="https://vaohang.com/?p=223" frameborder="0" width="100%"></iframe>
                                    </div>
                                    <div class="tab_country_3" v-if="tabSelected1 === 'tab_country_3'">
                                        <iframe src="https://vaohang.com/?p=225" frameborder="0" width="100%"></iframe>
                                    </div>
                                    <div class="tab_country_4" v-if="tabSelected1 === 'tab_country_4'">
                                        <iframe src="https://vaohang.com/?p=227" frameborder="0" width="100%"></iframe>
                                    </div>
                                    <div class="tab_country_5" v-if="tabSelected1 === 'tab_country_5'">
                                        <iframe src="https://vaohang.com/?p=229" frameborder="0" width="100%"></iframe>
                                    </div>
                                    <div class="tab_country_6" v-if="tabSelected1 === 'tab_country_6'">
                                        <iframe src="hhttps://vaohang.com/?p=231" frameborder="0" width="100%"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'News_vh',
    data () {
        return {
            listNew: [],
            tabSelected1: 'tab_country_1',
        }
    },
    created () {
        this.getListNew()
    },
    methods: {
        changeTab1 (tab) {
            this.tabSelected1 = tab
        },
        getListNew () {
            this.axios.get('https://vaohang.com/wp-json/hestia/v1/latest-posts').then((response) => {
                this.listNew = response.data
            })
        },
        format_date_new(value){
            if (value) {
                return moment(String(value)).format('DD-MM-YYYY')
            }
        }
    }
}
</script>
<style>
</style>
