<template>
    <div id="content" class="content-area page-wrapper container content-margin root_ltdedit_mb" role="main">
        <!-- <div class="icon_adv_mobile icon_adv_mobile1">
            <ul>
                <li>
                    <a :href="link_keobong" target="_blank"><img src="../assets/images/btn1.gif" alt=""></a>
                </li>
                <li>
                    <a :href="link_banner" target="_blank"><img src="../assets/images/btn.gif" alt=""></a>
                </li>
            </ul>
        </div> -->
        <div class="row row-main main_page_edit">
            <div class="large-12 col ltd_container">
                <div class="col-inner">
                    <div class="wp-schedule wrapper_match_calenda">

                        <!-- trực tiếp -->
                        <!-- <div class="item_tournament" v-for="(items, indexs) in Listitem" :key="indexs">
                            <div class="wp_item_tournament">
                                <h3><span>{{ indexs }}</span></h3>
                                <div class="match_tournament" v-for="(item1, index1) in items" :key="index1">
                                    <div class="div_01">{{ index1+1 }}.</div>
                                    <div class="div_02">{{ convertDate1(item1.showTimeDate) }}</div>
                                    <div class="div_03">{{ convertDate(item1.showTimeDate) }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ item1.homeName }}</span>
                                        <span class="vs_ltd">-</span>
                                        <span class="name_away_ltd">{{ item1.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- trong ngày -->
                        <!-- <div class="item_tournament" v-for="(items, indexs) in Listitems" :key="indexs">
                            <div class="wp_item_tournament">
                                <h3><span>{{ indexs }}</span></h3>
                                <div class="match_tournament" v-for="(item1, index1) in items" :key="index1">
                                    <div class="div_01">{{ index1+1 }}.</div>
                                    <div class="div_02">{{ convertDate1(item1.showTimeDate) }}</div>
                                    <div class="div_03">{{ convertDate(item1.showTimeDate) }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ item1.homeName }}</span>
                                        <span class="vs_ltd">-</span>
                                        <span class="name_away_ltd">{{ item1.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="item_tournament" v-for="(items, indexs) in listLtd" :key="indexs">
                            <div class="wp_item_tournament">
                                <h3><span>{{ items.profile.name }}</span></h3>
                                <div class="match_tournament" v-for="(item1, index1) in items.groupMatchs[0].listMatches" :key="index1">
                                    <div class="div_02">{{ formatTimeSramp(item1.matchTime) }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ item1.homeName }}</span>
                                        <span class="vs_ltd"><img :src="item1.homeIcon" alt=""> <b>-</b> <img :src="item1.awayIcon" alt=""></span>
                                        <span class="name_away_ltd">{{ item1.awayName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="item_tournament">
                            <div class="wp_item_tournament">
                                <div class="match_tournament" v-for="(item, indexs) in listLtd" :key="indexs">
                                    <div class="div_02">{{ formatTimeSramp(item.matchTime) }}</div>
                                    <div class="div_04">
                                        <span class="name_home_ltd">{{ item.homeTeamName }}</span>
                                        <span class="vs_ltd"><img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.homeTeamLogo" alt=""> <b>-</b> <img :src="'https://images-source-hk.oss-cn-hongkong.aliyuncs.com/football/team/' + item.awayTeamLogo" alt=""></span>
                                        <span class="name_away_ltd">{{ item.awayTeamName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
// import $ from 'jquery'
export default {
    name: "Lichthidau_vh",
    data() {
        return {
            Listitem: [],
            Listitems: [],
            dataRatio: [],
            dataRatio1: [],
            data_name_ratio: [],
            data_name_ratio1: [],
            dataTMR: [],

            listLtd: []
        }
    },
    created () {
        // setTimeout(() => {
        //     this.getTournament()
        // }, 500)
        // this.dataTomorow()
        // this.getDataLichThidau()
        this.getDataCalendaMatch()
    },
    methods: {
        getDataLichThidau () {
            // let today = new Date();
            // let datestart = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate() + ' ' + '00:00';
            // let dateend = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate() + ' ' + '23:59';
            // var d = new Date(datestart.replace(/-/g, "/"));
            // var d1 = new Date(dateend.replace(/-/g, "/"));
            // let startDate = Math.round(+d/1000)
            // let endDate = Math.round(+d1/1000)
            let today = new Date();
            var date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate()
            this.axios.get(this.api_ltds + '?date=' + date).then((response) => {
                this.listLtd = response.data.data
            });
        },
        getTournament () {
            // this.axios.get('https://lichthidau.com/process/refresh_index_page.jsp').then((response) => {
            //     this.items = response.data.content
            //     $(`.wp-schedule`).append(this.items);
            // })
            let today = new Date();
            let date = today.getDate();
            let dateNow = ''
            if(date < 10){
                dateNow = '0' + date
            }else{
                dateNow = date
            }
            let month = today.getMonth()+1;
            let date_month = dateNow + '_' + month;

            const form = new FormData();
            form.append('day', date_month);
            // 'https://keonhacai.1nguon.vip/b88/'+date_month+'/desktop/data.json' -> get

            this.axios.post(this.api_ratio, form).then((response) => {
                this.dataRatio = response.data.oddData.live.event_map; // lấy trận trực tiếp
                this.dataRatio1 = response.data.oddData.today.event_map; // lấy trận trong ngày

                const arr_matchfff = Object.entries(this.dataRatio1).map((arr) => ({
                    ...arr[1]
                }));

                let array_list = arr_matchfff.concat(this.dataTMR)
                const arr_result = Object.entries(array_list).map((arr) => ({
                    ...arr[1]
                }));

                // trực tiếp
                var name={};
                const arr_match = Object.entries(this.dataRatio).map((arr) => ({
                    ...arr[1]
                }));
                arr_match.forEach((item)=>{
                    if(name[item.leagueName]==undefined){
                        name[item.leagueName]=[];
                        name[item.leagueName].push(item)
                    }
                    else{
                        name[item.leagueName].push(item);
                    }
                });

                var name1={};
                const arr_match1 = Object.entries(arr_result).map((arr) => ({
                    ...arr[1]
                }));
                arr_match1.forEach((item)=>{
                    if(name1[item.leagueName]==undefined){
                        name1[item.leagueName]=[];
                        name1[item.leagueName].push(item)
                    }
                    else{
                        name1[item.leagueName].push(item);
                    }
                });
                this.Listitem = name
                this.Listitems = name1
                // console.log(this.Listitem, this.Listitems);
            })
        },
        dataTomorow(){
            let today = new Date();
            let tomorrow = new Date();
            let date = ''
            var dateTomorrow = new Date(tomorrow.setDate(today.getDate() + 1)).toLocaleDateString("en-US")
            var dateTomorrow1 = dateTomorrow.split('/')

            if(dateTomorrow1[1] < 10){
                date = '0' + dateTomorrow1[1]
            }else{
                date = dateTomorrow1[1]
            }
            var dateTomorrow2 = date + '_' + dateTomorrow1[0]

            const form = new FormData();
            form.append('day', dateTomorrow2);
            // 'https://keonhacai.1nguon.vip/b88/'+dateTomorrow2+'/desktop/data.json' -> get

            this.axios.post(this.api_ratio, form).then((response) => {
                var listdata = response.data.oddData.today.event_map; // lấy trận trong ngày mai
                var arrdata = Object.entries(listdata).map((arr) => ({
                    ...arr[1]
                }));
                this.dataTMR = arrdata
            });
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('HH:mm DD/MM')
            }
        },
        convertDate (date) {
            var datecv = new Date(date);
            var dateconvert = datecv.toLocaleString()
            var datesp = dateconvert.split(' ')
            return datesp[0]
        },
        convertDate1 (date) {
            var datecv = new Date(date);
            var dateconvert = datecv.toLocaleString()
            var datesp = dateconvert.split(' ')
            return datesp[1]
        },
        formatTimeSramp (str) {
            const timestamp = str * 1000;
            const date = new Date(timestamp);
            const dd = String(date.getDate()).padStart(2, '0');
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            // const yyyy = date.getFullYear();
            const hh = String(date.getHours()).padStart(2, '0');
            const ii = String(date.getMinutes()).padStart(2, '0');
            // const ss = String(date.getSeconds()).padStart(2, '0');
            const formattedDate = `${dd}/${mm} ${hh}:${ii}`;
            return formattedDate;
        },

        getDataCalendaMatch () {
            const postData = {
                pageNum: 1,
                pageSize: 100,
                query: {
                    sportId: 1,
                },
            }
            this.axios.post(this.api_listcalendaMatch, postData, {
                headers: {
                    'language-config': 'en'
                }
            }).then((response) => {
                this.listLtd = response.data.data.records
            })
        }
    }
}
</script>
<style>
</style>
